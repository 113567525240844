import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Hidden, Paper, ShiftTheme, makeStyles } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import type { FeesTermsAndPaymentTypes } from 'src/pages/Buyer/Review/types';

import { WeeklyRepaymentPanel } from 'src/pages/Buyer/Review/WeeklyRepaymentPanel';

import { type PaymentMethod, PaymentMethodValues } from '../../api/shiftPaymentsApi';
import startupConfigSelectors from '../../components/StartupConfig/selectors';
import { calculateSupplierTermsInDays } from '../../pages/Buyer/Review/calculateTransactionFee';
import PaymentDateSelector from '../../pages/Buyer/Review/PaymentDateSelector';

import WeekSelector from '../../pages/Buyer/Review/WeekSelector';
import AlertError from '../AlertError';
import { PaymentMethodRadioBox } from '../PaymentMethodRadioBox';

const useStyles = makeStyles((theme: ShiftTheme) => ({
  radio: {
    textAlign: 'center',
  },
  payMode: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 4, 4, 4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  expansionSummary: {
    '&.MuiExpansionPanelSummary-root': {
      [theme.breakpoints.up('sm')]: {
        padding: 0,
      },
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
      '& .MuiExpansionPanelSummary-content': {
        margin: 0,
      },
    },
    display: 'block',
    minHeight: 'auto !important',
  },
  expansionDetails: {
    margin: 0,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
  selectPanelSelected: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#E9F0F5',
    borderRadius: '4px',
    boxShadow: 'none !important',
  },
  selectPanelDefault: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#E9F0F5',
    borderRadius: '4px',
    boxShadow: 'none !important',
    marginTop: '16px',
    position: 'inherit',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  validationError: {
    marginTop: theme.spacing(2),
  },
}));

export type PaymentMethodRadioGroupProps = {
  paymentTypes: string;
  paymentMethodDefaultValue: PaymentMethod;
  invoiceCreatedDate: Date;
  supplierDueDate: Date;
  isBuyerInitiated: boolean;
  transactionFee: number;
  supplierTerms: number;
  supplierTermPeriod: string;
  feesTermsAndPaymentTypes: FeesTermsAndPaymentTypes;
  invoiceAmount: number;
  validationError: string;
  selectionChanged: (value: string) => void;
};

export function PaymentMethodRadioGroup(props: PaymentMethodRadioGroupProps) {
  const {
    paymentTypes,
    paymentMethodDefaultValue,
    invoiceCreatedDate,
    supplierDueDate,
    isBuyerInitiated,
    transactionFee,
    supplierTerms,
    supplierTermPeriod,
    feesTermsAndPaymentTypes,
    invoiceAmount,
    validationError,
    selectionChanged,
  } = props;
  const classes = useStyles();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(paymentMethodDefaultValue);

  const configuration = useSelector(startupConfigSelectors.selectConfiguration);
  const isWeeklyRepaymentUpliftEnabled = configuration?.featureConfiguration?.weeklyRepaymentUpliftEnabled;

  const handleExpansionPanelChange = (isFullPanel: boolean) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    const value = isFullPanel === isExpanded ? PaymentMethodValues.Full : PaymentMethodValues.Weekly;
    setPaymentMethod(value);
    selectionChanged(value);
  };

  return (
    <Paper className={classes.payMode}>
      {(paymentTypes === null || paymentTypes.includes(PaymentMethodValues.Full)) && (
        <ExpansionPanel
          expanded={paymentMethod === PaymentMethodValues.Full}
          className={paymentMethod === PaymentMethodValues.Full ? classes.selectPanelSelected : classes.selectPanelDefault}
          onChange={handleExpansionPanelChange(true)}
        >
          <ExpansionPanelSummary className={classes.expansionSummary}>
            <PaymentMethodRadioBox
              checked={paymentMethod === PaymentMethodValues.Full}
              text='Repay in full'
              secondaryText='You will be debited on the due date shown'
              testId='payInFull'
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDetails}>
            <Grid container>
              <Hidden smDown>
                <Grid item xs={1} />
              </Hidden>
              <Grid item xs={12} sm={11}>
                <PaymentDateSelector
                  invoiceDate={invoiceCreatedDate}
                  supplierDueDate={supplierDueDate}
                  isBuyerInitiated={isBuyerInitiated}
                  availableDays={feesTermsAndPaymentTypes.availableDays}
                  transactionFee={transactionFee}
                  paymentDate={null}
                  supplierTerms={calculateSupplierTermsInDays(invoiceCreatedDate, supplierTerms, supplierTermPeriod)}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {(paymentTypes === null || paymentTypes.includes(PaymentMethodValues.Weekly)) && (
        <ExpansionPanel
          expanded={paymentMethod === PaymentMethodValues.Weekly}
          className={paymentMethod === PaymentMethodValues.Weekly ? classes.selectPanelSelected : classes.selectPanelDefault}
          onChange={handleExpansionPanelChange(false)}
        >
          <ExpansionPanelSummary className={classes.expansionSummary}>
            <PaymentMethodRadioBox
              checked={paymentMethod === PaymentMethodValues.Weekly}
              text='Repay in instalments'
              secondaryText='Pay for your invoice over weekly instalments'
              testId='payInInstallments'
            />
          </ExpansionPanelSummary>
          <ExpansionPanelSummary className={classes.expansionSummary}>
            {!!isWeeklyRepaymentUpliftEnabled && (
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <Grid item xs={12} sm={12}>
                  <WeeklyRepaymentPanel feeOptions={feesTermsAndPaymentTypes.weekly} repaymentDayOfTheWeek={feesTermsAndPaymentTypes.repaymentDay} />
                </Grid>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanelSummary>
          {!isWeeklyRepaymentUpliftEnabled && (
            <ExpansionPanelDetails className={classes.expansionDetails}>
              <Grid container>
                <Hidden smDown>
                  <Grid item xs={1} />
                </Hidden>
                <Grid item xs={12} sm={11}>
                  <WeekSelector
                    fees={feesTermsAndPaymentTypes.weekly}
                    transactionFee={transactionFee}
                    invoiceAmount={invoiceAmount}
                    balance={feesTermsAndPaymentTypes.balance}
                    nextPaymentAmount={feesTermsAndPaymentTypes.nextPaymentAmount}
                    isBuyerInitiated={isBuyerInitiated}
                    installmentTermInWeeks={0}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      )}
      {validationError ? (
        <div className={classes.validationError}>
          <AlertError message={validationError} data-testid='uia-error' />
        </div>
      ) : null}
    </Paper>
  );
}
